






















































import { Component, Vue, Prop } from "vue-property-decorator";
import HierarchyMappingService from "@/services/HierarchyMappingService";
import ActionablePopup from "@/components/common/Popup/ActionablePopup.vue";

export default Vue.extend({
  components: { ActionablePopup,
  },
  props: {},
  data() {
    return {
      PlanTypeModel: [
        {
          userRole: 0,
          name: "",
          journeyPlanType: 0,
        },
      ],
      confirmationPopup: false,
      confirmationPopupTitle: "",
      confirmationPopupSubtitle: "",
      confirmationPopupText: "",
      cancelBtnText: "",
      confirmBtnText: "",
      JourneyPlanType: [
        {
          id: 0,
          Name: "Not Created",
        },
        {
          id: 1,
          Name: "User will create",
        },
        {
          id: 2,
          Name: "Admin will create",
        },
      ],
    };
  },
  computed: {},
  methods: {
    async getUserRole(): Promise<boolean> {
      return new Promise<boolean>((res) => {
        HierarchyMappingService.GetPortalRole()
          .then((response) => {
            console.log(response);
            var result = response.data;
            this.PlanTypeModel = response.data;
            res(true);
          })
          .catch((error): any => {
            console.log(error);
          });
      });
    },
    Submit() {
      this.confirmationPopupTitle = "Create Journey Plan Type";
      this.confirmationPopupSubtitle =
        "Are you sure you want to set the journey plan types to these values?";
      this.confirmationPopupText = "";
      this.cancelBtnText = "No";
      this.confirmBtnText = "Yes";
      this.confirmationPopup = true;
    },
    OnSubmit() {},
    actionablePopup() {
      this.confirmationPopup = false;
       window.location.reload();
    },
    async confirmAction() {
      let value = Object.assign([], this.PlanTypeModel);
      console.log(value);
      await HierarchyMappingService.UpdatePlan(value)
        .then((response) => {
          console.log(response);

          this.actionablePopup();
        })
        .catch((err: any) => {
                    console.log(err);
                });
    },
  },
  mounted() {
    this.getUserRole();
  },
});
