import { IJourneyData } from '@/interfaces/IJourneyData';
import client from '../apiService';

class HierarchyMappingService{
    async GetPortalRole(){
        const url = `Employee/GetRolewiseJourneyPlan`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
       return result;
    }
    async UpdatePlan(Norm: Array<IJourneyData>){
        const url = `Employee/UpdateJourneyPlanType`;
        return await client.post(url, Norm, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

}
export default new HierarchyMappingService();